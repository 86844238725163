import { StrictMode } from 'react';
import '@fontsource/inter';
import ReactDOM from 'react-dom/client';
import { CssVarsProvider } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom';

import './modules/firebase';
import Dashboard from './components/Dashboard';
import Avoidance from './components/Avoidance';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
    children: [
      { index: true, element: <Navigate to="/avoidance" replace /> },

      {
        path: 'avoidance',
        element: <Avoidance />
      }
    ]
  }
]);

const App = () => {
  return (
    <CssVarsProvider defaultMode="system">
      <CssBaseline />
      <RouterProvider router={router} />
    </CssVarsProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <App />
  </StrictMode>
);
