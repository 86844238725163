import '@fontsource/inter';
import {
  Typography,
  Textarea,
  Button,
  LinearProgress,
  Divider,
  Box
} from '@mui/joy';
import { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Player } from '@lottiefiles/react-lottie-player';
import { RestartAlt } from '@mui/icons-material';

import dancing from '../lottie/monkey.json';

const App = () => {
  const [message, setMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const [response, setResponse] = useState();

  const reset = () => {
    setMessage('');
    setResponse();
  };

  const processMessage = async () => {
    setProcessing(true);
    const createChatCompletion = httpsCallable(
      getFunctions(),
      'createChatCompletion'
    );
    const response = await createChatCompletion({
      messages: [
        {
          role: 'system',
          content:
            'You are a professional life coach. You specialise in helping people who are avoiding something in their life. You will give them tips on how to make progress towards what they are avoiding. This may include breaking the objective down into smaller tasks.'
        },
        {
          role: 'user',
          content: message
        }
      ]
    });

    setResponse(response.data);
    setProcessing(false);
  };

  let dashboard;
  if (processing) {
    dashboard = (
      <>
        <Typography level="body-md" sx={{ fontStyle: 'italic', mb: 3 }}>
          Thinking... this might take a minute!
        </Typography>
        <LinearProgress />
      </>
    );
  } else if (!response) {
    dashboard = (
      <>
        <Textarea
          placeholder="What are you avoiding?"
          minRows={8}
          autoFocus
          sx={{ my: 3 }}
          onChange={e => setMessage(e.target.value)}
          value={message}
        />

        <Box justifyContent="center" display={'flex'}>
          <Button onClick={processMessage} size="lg">
            Submit
          </Button>
        </Box>
      </>
    );
  } else {
    dashboard = (
      <>
        <Typography level="body-md" sx={{ fontStyle: 'italic' }}>
          {message}
        </Typography>
        <Typography level="body-md" sx={{ whiteSpace: 'pre-line', my: 3 }}>
          {response}
        </Typography>
        <Player autoplay loop={true} src={dancing} />
        <Box justifyContent="center" display={'flex'}>
          <Button
            onClick={reset}
            size="sm"
            variant="outlined"
            startDecorator={<RestartAlt />}
          >
            Start over
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography level="h1" sx={{ py: 1 }}>
        Avoidance coaching
      </Typography>
      <Typography level="title-lg">
        Make progress on what you're avoiding
      </Typography>
      <Divider sx={{ my: 3 }} />
      {dashboard}
    </>
  );
};

export default App;
