import { Button, Grid, Divider, Box } from '@mui/joy';
import { Copyright } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
  return (
    <Grid container justifyContent="center">
      <Grid xs={12} md={6} lg={5} sx={{ py: 1, px: 2 }}>
        <Outlet />
        <Divider sx={{ my: 3 }} />
        <Box justifyContent="center" display="flex">
          <Button
            component="a"
            target="_blank"
            href="https://golightlyplus.com"
            startDecorator={<Copyright />}
            variant="text"
          >
            {new Date().getFullYear()} Golightly+
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
