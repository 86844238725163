import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyC2EdJzLBedqvxbyGTTwehKPofBF8B3WOM',
  authDomain: 'mvps-1111.firebaseapp.com',
  projectId: 'mvps-1111',
  storageBucket: 'mvps-1111.appspot.com',
  messagingSenderId: '425305874188',
  appId: '1:425305874188:web:7eea5e20e4c1403940f329'
};

initializeApp(firebaseConfig);
